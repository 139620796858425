.imageDiff {
  padding: 10px;
  text-align: center;
}

.img-removed {
  background-color: rgba(230, 70, 100, 0.2);
}

.img-added {
  background-color: rgba(70, 230, 100, 0.2);
}
